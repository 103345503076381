import LogoWhite from '@assets/logos/white.svg';
import './style.css';
import Buttom from '@components/buttons';
import { Link, useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { ReactComponent as BinanceIcon } from '@assets/icons/binance.svg';

const Index = () => {
  const navigate = useNavigate();

  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    const referCode = queryParams.get('refercode');

    if (referCode) {
      localStorage.setItem('refercode', referCode);
      console.log('Refercode saved:', referCode);
    } else {
      localStorage.removeItem('refercode');
    }
  }, [location]);

  return (
    <div className="launch-container">
      <nav>
        <div className="content-nav">
          <Link to="/">
            <img src={LogoWhite} alt="MiningOptions" className="logoLaunch" />
          </Link>
          <div>
            <ul>
              <li>
                <Link to="/dmo-token">DMO Token</Link>
              </li>
              <li>
                <a href="#">White Paper</a>
              </li>
              <li>
                <Link to="/#faq">FAQ</Link>
              </li>
              <li>
                <a href="#">Team</a>
              </li>
              <li>
                <Link to="/contact-us">Contact us</Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="social-network">
          <i className="fab fa-telegram-plane"></i>
          <i className="fab fa-twitter"></i>
          <i className="fab fa-instagram-square"></i>
          <i className="fab fa-youtube"></i>
          <BinanceIcon />
        </div>
      </nav>
      <br />
      <section>
        <div id="usability">
          <div className="item-usability left">
            <div className="item">
              <h3>NO FEES</h3>
            </div>
            <div className="item">
              <h3>DESCENTRALIZED</h3>
            </div>
          </div>
          <div id="image-phone">
            <img src={require('./assets/trade.png')} alt="" />
          </div>
          <div className="item-usability right">
            <div className="item">
              <h3>INSTANT ORDER EXECUTION</h3>
            </div>
            <div className="item">
              <h3>NO IMPACT ON PRICE</h3>
            </div>
          </div>
        </div>
      </section>
      <section id="about" className="content">
        <h1>DESCENTRALIZED MARGIN OPTIONS</h1>
        <div className="card-home">
          <p>
            Margin trading on the web 3 has never been easier, faster and safer,
            with full control of your funds and easy access to the most powerful
            margin trading tools available such as limit, market, stop loss and
            stop profit orders. No commissions and no impact on price connected
            to the exchanges with the largest spot volume on the market with
            leverage of up to 50x. Position openings and closings are carried
            out immediately and according to the values ​​delivered, achieving
            the same performance as CEX but maintaining custody of your funds
            and without paying high commissions. In addition, you will have
            permanent access to liquidity and volume data so that authenticity
            is always verified and regulated.
          </p>
        </div>
      </section>
      <br />
      <section id="core-features" className="content">
        <center>
          <h1>CORE FEATURES</h1>
        </center>
        <br />

        <div className="flex-row">
          <div className="card-home">
            <h2>DMO Token</h2>
            <p>
              Our token has a fixed supply and represents a value within our DEX
              ecosystem, which has a constant repurchase system equivalent to
              10% of the income obtained each quarter. These tokens are
              distributed for staking rewards, guaranteeing participants
              constant revaluation and liquidity.
            </p>
          </div>
          <div className="card-home">
            <h2>Liquidity provider</h2>
            <p>
              Transactions are executed and processed automatically without any
              extra commission to the network cost, for the immediate
              availability of funds in each transaction we use a public
              monitoring liquidity contract authorized and regulated with the
              corresponding brokerage licenses.
            </p>
          </div>
        </div>
        <br />
        <br />

        <div className="content">
          <div className="flex-row">
            <div className="card-home">
              <p className="text-center">
                Earn fixed stake earnings regardless of your participation level
              </p>
            </div>
            <div className="card-home">
              <p className="text-center">
                Trade on margin without any commission on web 3
              </p>
            </div>
            <div className="card-home">
              <p className="text-center">
                Telegram mini apps support on ton network
              </p>
            </div>
          </div>
        </div>

        <br />

        <div className="content">
          <div className="flex-row">
            <div className="card-home">
              <p className="text-center">
                Real spot prices from major exchanges without any price
                manipulation
              </p>
            </div>
            <div className="card-home">
              <p className="text-center">
                Algorithm in Inter-Blockchain Communication (IBC) Protocol
              </p>
            </div>
            <div className="card-home">
              <p className="text-center">
                Intuitive interface and the most popular options for margin
                trading
              </p>
            </div>
          </div>
        </div>
      </section>
      <br />
      <br /> <br />
      <br />
      <section className="content" id="start-you-crypto">
        <br />
        <div className="card-home">
          <div className="flex-row">
            <div className="item-start-your">
              <h2 className="text-center">
                Have fun accumulating $DMO tokens and get great benefits at
                launch
              </h2>
              <Buttom
                size="lg"
                label="Register Now"
                onClick={() => {
                  navigate('/sing-in');
                }}
              />
            </div>
            <div className="item-start-your">
              <div className="flex-row justify-content-center">
                <img
                  style={{ width: '80%' }}
                  src="https://static.vecteezy.com/system/resources/thumbnails/027/663/854/small_2x/mockup-iphone-10-10s-11-11pro-and-new-iphone-12-12pro-12-mini-mock-up-screen-iphone-vector-illustration-generative-ai-png.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        <br />
      </section>
      <br />
      <br />
      <section className="content">
        <div className="flex-row">
          <div className="card-home" id="content-footer-logo">
            <img src={LogoWhite} alt="MiningOptions" className="logofooter" />
            <p>
              The most innovative and profitable staking options in a one app
            </p>
            <div className="social-network-footer">
              <i className="fab fa-telegram-plane"></i>
              <i className="fab fa-twitter"></i>
              <i className="fab fa-instagram-square"></i>
              <i className="fab fa-youtube"></i>
              <BinanceIcon />
            </div>
          </div>
          <div id="footer-btns">
            <div>
              <div>Mining Options</div>
              <div>White Paper</div>
              <div>Team</div>
              <div>Contact Us</div>
            </div>
          </div>
        </div>
      </section>
      <br />
      <br />
    </div>
  );
};

export default Index;
