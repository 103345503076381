import LogoWhite from '@assets/logos/white.svg';
import './style.css';
import Buttom from '@components/buttons';
import { Link, useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { ReactComponent as BinanceIcon } from '@assets/icons/binance.svg';

const Index = () => {
  const navigate = useNavigate();

  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    const referCode = queryParams.get('refercode');

    if (referCode) {
      localStorage.setItem('refercode', referCode);
      console.log('Refercode saved:', referCode);
    } else {
      localStorage.removeItem('refercode');
    }
  }, [location]);

  return (
    <div className="launch-container">
      <nav>
        <div className="content-nav">
          <Link to="/">
            <img src={LogoWhite} alt="MiningOptions" className="logoLaunch" />
          </Link>
          <div>
            <ul>
              <li>
                <Link to="/dmo-token">DMO Token</Link>
              </li>
              <li>
                <a href="#">White Paper</a>
              </li>
              <li>
                <Link to="/#faq">FAQ</Link>
              </li>
              <li>
                <a href="#">Team</a>
              </li>
              <li>
                <a href="#">Contact us</a>
              </li>
            </ul>
          </div>
        </div>
        <div className="social-network">
          <i className="fab fa-telegram-plane"></i>
          <i className="fab fa-twitter"></i>
          <i className="fab fa-instagram-square"></i>
          <i className="fab fa-youtube"></i>
          <BinanceIcon />
        </div>
      </nav>
      <br />

      <br />

      <br />
      <section className="content">
        <h1>CONTACT US</h1>
        <br />
        <div id="form-contact">
          <div className="flex-row">
            <input type="text" placeholder="Personal email (Required)" />
            <input type="text" placeholder="UID or name (Optional)" />
          </div>
          <div>
            <br />
            <textarea name="" id=""></textarea>
          </div>
          <br />
          <div id="content-submit">
            <div id="btn-submit">Send Message</div>
          </div>
        </div>
      </section>
      <br />
      <br />
    </div>
  );
};

export default Index;
